import { useEffect, useState } from 'react'
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom'
import { Button, ButtonProps, Col, Divider, Flex, Row, Typography, notification } from 'antd'
import { Helmet } from 'react-helmet'

import { FullLoader } from 'components/FullLoader'
import { AppFooter } from '../../app/AppFooter'
import { AuthPage } from './components/AuthPage'
import { Logo } from '../../components/Logo'

import { api } from 'utils/axios'
import { auth, logout } from 'utils/firebase'
import { getErrorMsg } from 'utils/geterror'

const { Title, Paragraph } = Typography

export function VerifyEmailPage() {
  const navigate = useNavigate()

  const [searchParams] = useSearchParams()
  const mode = searchParams.get('mode')

  const [fbUser, setFbUser] = useState<any>(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setFbUser(user)
    })

    return () => unsubscribe()
  }, [])

  useEffect(() => {
    const i = setInterval(() => {
      auth.currentUser?.reload()
      setFbUser(auth.currentUser || null)
      if (auth.currentUser?.emailVerified) navigate('/')
    }, 1000)
    return () => clearTimeout(i)
  }, [])

  const btnProps: ButtonProps = {
    loading,
    block: false,
    type: 'primary',
  }

  const onCancel = () => {
    logout()
    navigate('/auth/signin')
  }

  const onResend = () => {
    setLoading(true)
    // resend email
    api
      .post('/auth/verify-email')
      .then(() =>
        notification.success({
          message: 'Email sent',
          description: 'We sent an email to you with a link to get started.',
        }),
      )
      .catch((err) => notification.error(getErrorMsg(err)))
      .finally(() => setLoading(false))
  }


  if (!!fbUser?.emailVerified) return <Navigate to={'/'} />
  if (mode === 'verifyEmail') return <FullLoader />
  if (!fbUser) return <FullLoader />

  return (
    <AuthPage>
      <Helmet>
        <title>Equility - Verify your email address</title>
      </Helmet>
      <Row style={contentStyle}>
        <Col span={24}>
          <Flex vertical justify="center" align="center">
            <Title level={1}>
              <Logo />
            </Title>

            <Title level={3} style={titleStyle}>
              Verify your email address
            </Title>

            <Paragraph> We sent an email to you with a link to get started.</Paragraph>

            <Flex gap={10}>
              <Button {...btnProps} onClick={onResend}>
                Resend email
              </Button>
              <Button danger onClick={onCancel}>
                Cancel
              </Button>
            </Flex>
          </Flex>
          <Divider />
        </Col>

        <Col span={24}>
          <AppFooter />
        </Col>
      </Row>
    </AuthPage>
  )
}

const contentStyle = {
  width: 800,
}
const titleStyle: React.CSSProperties = {
  marginTop: 0,
  marginBottom: 10,
}
