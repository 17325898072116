import firebase from 'firebase/compat/app'
import * as firebaseui from 'firebaseui'
import * as firebaseauth from 'firebase/auth'

import { initializeApp } from 'firebase/app'

import { setAuth, setAuthLoading, setToken } from 'store/auth.store'
import { config } from './config'
import { shareAuth } from './plugin'
import { notification } from 'antd'

const firebaseApp = initializeApp(config.firebase)
const auth = firebaseauth.getAuth(firebaseApp)

const ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(auth)

// Wait for firebase to initialize
setAuthLoading(true)
auth.authStateReady().then(() => setAuthLoading(false))

firebaseauth.onIdTokenChanged(auth, (user) => {
  user ? setAuth(user.uid) : setAuth(null)
  user?.getIdToken().then((token) => {
    setToken(token)
    shareAuth(token ? { token } : null)
  })
})

firebaseauth.onAuthStateChanged(auth, (user) => {
  user ? setAuth(user.uid) : setAuth(null)
  user?.getIdToken().then((token) => {
    setToken(token)
    shareAuth(token ? { token } : null)
  })
})

document.addEventListener(
  'DOMContentLoaded',
  () => {
    // Get the action to complete.
    const mode = getParameterByName('mode')
    // Get the one-time code from the query parameter.
    const actionCode = getParameterByName('oobCode')

    if (!actionCode) return

    // Handle the user management action.
    switch (mode) {
      case 'resetPassword':
        // Display reset password handler and UI.
        // handleResetPassword(auth, actionCode, continueUrl, lang)
        break
      case 'recoverEmail':
        // Display email recovery handler and UI.
        // handleRecoverEmail(auth, actionCode, lang)
        break
      case 'verifyEmail':
        // Display email verification handler and UI.
        handleVerifyEmail(actionCode)
        break

      case 'verifyAndChangeEmail':
        handleVerifyEmail(actionCode)
        break
      default:
      // Error: invalid mode.
    }
  },
  false,
)

function handleVerifyEmail(actionCode: string) {
  console.log('handleVerifyEmail', actionCode)
  firebaseauth
    .applyActionCode(auth, actionCode)
    .then(() => {
      firebaseauth.getAuth().currentUser?.reload()
      setTimeout(() => {
        firebaseauth.getAuth().currentUser?.reload()
        document.location.href = '/'
      }, 1000)
      notification.success({
        message: 'Email verified',
        description: 'Your email has been verified',
      })
    })
    .catch((error) => {
      console.log('handleVerifyEmail', error)
      // Code is invalid or expired. Ask the user to verify their email address again.
      notification.error({
        message: 'Error verifying email',
        description: 'The code is invalid or expired. Please request a new one.',
      })
    })
}

// Get the user's JWT token
const getIdToken = () => auth.authStateReady().then(() => auth.currentUser?.getIdToken())
const isEmailVerified = () => auth.authStateReady().then(() => auth.currentUser?.emailVerified)
// Sign out the user
const logout = () => firebaseauth.signOut(auth)
// Sign in functions
const signInWithPassword = (email: string, pass: string) => 
  firebaseauth.signInWithEmailAndPassword(auth, email, pass)
const signInWithGoogle = () => 
  firebaseauth.signInWithPopup(auth, new firebaseauth.GoogleAuthProvider())
const signUpWithPassword = (email: string, pass: string) =>
  firebaseauth.createUserWithEmailAndPassword(auth, email, pass)
const forgotPassword = (email: string) => 
  firebaseauth.sendPasswordResetEmail(auth, email)
// Reset password
const resetPassword = (actionCode: string, newPassword: string) => 
  firebaseauth.confirmPasswordReset(auth, actionCode, newPassword)
// Verify email
const verifyEmail = (actionCode: string) => 
  firebaseauth.applyActionCode(auth, actionCode)
const sendEmailVerification = (user: firebaseauth.User) => 
  firebaseauth.sendEmailVerification(user)
const changePassword = (user: firebaseauth.User, newPassword: string) => 
  firebaseauth.updatePassword(user, newPassword)
const changeEmail = (user: firebaseauth.User, newEmail: string) => 
  firebaseauth.updateEmail(user, newEmail)
const verifyBeforeUpdateEmail = (user: firebaseauth.User, newEmail: string) =>
  firebaseauth.verifyBeforeUpdateEmail(user, newEmail)
// Validate password
const validatePassword = (user: firebaseauth.User, oldPassword: string) => {
  if (!user.email) return Promise.reject('Invalid user')
  const credential = firebaseauth.EmailAuthProvider.credential(user.email, oldPassword)
  return firebaseauth.reauthenticateWithCredential(user, credential)
}

export {
  getIdToken,
  logout,
  signInWithPassword,
  signInWithGoogle,
  signUpWithPassword,
  forgotPassword,
  verifyEmail,
  isEmailVerified,
  sendEmailVerification,
  verifyBeforeUpdateEmail,
  changePassword,
  changeEmail,
  validatePassword,
  resetPassword,
  ui,
  firebase,
  auth,
}

function getParameterByName(name: string) {
  return new URLSearchParams(window.location.search).get(name)
}
