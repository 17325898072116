import useSWR from 'swr'
import * as fb from 'utils/firebase'

import { useCompanyStore } from 'store/company.store'
import type { IUser } from 'types/user.types'

export function useUser() {
  const { data, error, isLoading } = useSWR<IUser>('user', { revalidateOnFocus: false })
  const resetCashe = useCompanyStore((state) => state.reset)

  const logout = () => {
    fb.logout()
    resetCashe()
  }

  return {
    user: data,
    isLoading,
    error,
    logout,
  }
}
