import { useEffect, useState } from 'react'
import { AutoComplete, Button, Modal, Select, Table, notification } from 'antd'

import { ColorNumber } from 'components/ColorNumber'

import { useAccounts } from 'hooks/useAccounts'
import { useCompany } from 'hooks/useCompany'
import { useVendors } from 'hooks/useVendors'
import { api } from 'utils/axios'
import { formatDate } from 'utils/dates'
import type { IAccount } from 'types/account.types'
import type { IQboCustomer } from 'types/company.types'
import { TRANSACTION_TYPES, type ITx } from 'types/tx.types'

interface Props {
  accountId: string
  list: ITx[]
  open: boolean
  onClose: (refresh?: boolean) => void
}

export function AddToQBO(props: Props) {
  const { accountId, list, open, onClose } = props

  const { accounts } = useAccounts()
  const { company } = useCompany()

  const [loading, setLoading] = useState(false)
  const [meta, setMeta] = useState<any>({})
  const [copy, setCopy] = useState<any>()

  const account = accounts.find(v => v.platformId === accountId)
  const typeOptions = getTypeOptions(!!account?.isLiability, !!account?.isBank)

  const { customers, vendors } = useVendors()
  const agents = [...customers, ...vendors]

  useEffect(() => {
    const newMeta = {} as any
    list.forEach(tx => {
      if (!!account?.isBank) 
        newMeta[tx._id] = account.isLiability 
          ? { type: tx.amount >= 0 ? 0 : 1 }
          : { type: tx.amount >= 0 ? 1 : 0 }
      else {
        newMeta[tx._id] = { type: 0 } 
      }
    })
    setMeta(newMeta)
    setLoading(false)
  }, [list, open])

  if (!account) return null

  console.log('Meta', meta)

  const handleUpdateTx = (tx: ITx, txmeta: any) => {
    const newMeta = Object.assign({}, meta)
    newMeta[tx._id] = newMeta[tx._id] || {}
    newMeta[tx._id] = {...newMeta[tx._id], ...txmeta}
    // console.log('New Meta', newMeta)
    setMeta(newMeta)
  }

  const handleSubmit = () => {
    const transactions = list.map(tx => prepareTx(tx, meta, account, typeOptions, customers))

    if (transactions.find(v => !v.accountId))
      return notification.warning({
        message: 'Missing account',
        description: 'Please select an account for all transactions'
      })

    setLoading(true)

    api.post('/accounting/transactions', { transactions, companyId: company?.id})
      .then(() => {
        notification.info({ message: 'Success!', description: 'Transaction(s) added to QuickBooks' })
        onClose(true)
      })
      .catch(() => notification.error({ message: 'Error adding transaction(s) to QuickBooks' }))
      .finally(() => setLoading(false))
  }

  return (
    <Modal
      width={1300}
      title="Export To QuickBooks"
      open={open}
      onCancel={() => onClose()}
      footer={[
        <Button onClick={() => onClose()} key="ertc">Cancel</Button>,
        <Button onClick={handleSubmit} type="primary" key="ertok" loading={loading}>Upload</Button>
      ]}
      children={
        <Table
          size="small"
          className="Table"
          rowKey={'_id'}
          dataSource={list}
          columns={columns(accounts, agents, typeOptions, meta, handleUpdateTx, copy, setCopy)}
          pagination={false}
          scroll={{ y: 400 }}
        />
      }
    />
  )
}

function columns(
  accounts: IAccount[], 
  agents: IQboCustomer[], 
  typeOptions: any[], 
  meta: any, 
  updateTx: (tx: ITx, data: any) => void,
  accCopy: any, setAccCopy: (v: any) => void
) {
  return [
    {
      title: 'Date',
      key: 'date',
      dataIndex: 'date',
      width: 130
    },
    {
      title: 'Account',
      key: 'account',
      dataIndex: 'account',
      width: 310,
      render: (_: number, tx: ITx) => {
        const value = accounts.find(v => v.platformId === meta[tx._id]?.accountId)?.name
        return (
          <CustomSelect 
            value={value}
            options={accounts.map(v => ({ key: v.platformId, value: v.name }))}
            onChange={v => updateTx(tx, { accountId: v })}
          />
        )
      }
    },
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      render: (_: number, tx: ITx) => {
        const value = agents.find(v => v.Id === meta[tx._id]?.name)?.DisplayName
        return (
          <CustomSelect 
            value={value}
            options={agents.map(v => ({ key: v.Id, value: v.DisplayName }))}
            onChange={v => updateTx(tx, { name: v })}
          />
        )
      }
    },
    {
      title: 'Memo',
      key: 'description',
      dataIndex: 'description',
      ellipsis: true
    },
    {
      title: 'Type',
      key: 'type',
      dataIndex: 'type',
      width: 210,
      render: (_: string, tx: ITx) => {
        return (
          <Select
            style={{ width: 200 }}
            options={typeOptions}
            value={meta[tx._id]?.type}
            onChange={v => updateTx(tx, { type: v })}
          />
        )
      }
    },
    {
      title: 'Amount',
      key: 'amount',
      dataIndex: 'amount',
      width: 140,
      render: (v: number) => <ColorNumber amount={v} />
    }
  ]
}

interface CustomSelectProps {
  options: any[]
  value: any
  onChange: (v: any) => void
}

const CustomSelect = (props: CustomSelectProps) => {
  const { options, value, onChange } = props

  const [val, setVal] = useState(value)

  const status = val
    ? options.some(v => v.value.includes(val)) ? '' : 'error'
    : ''

  return (
    <AutoComplete 
      showSearch
      allowClear
      placeholder="Select a name"
      disabled={!options.length}
      style={{ width: '100%' }}
      options={options}
      filterOption={(input, option) => (option?.value ?? '').toLowerCase().includes(input.toLowerCase())}
      defaultValue={value}
      value={val}
      status={status}
      onChange={(_, data) => {
        setVal(_)
        data?.key ? onChange(parseInt(data.key)) : onChange(undefined)
      }}
      onBlur={() => {
        if (status === 'error')
          setVal(undefined)
      }}
    />
  )

}

function prepareTx(tx: ITx, meta: any, account: IAccount, typeOptions: any[], customers: IQboCustomer[]) {
  const type = typeOptions[meta[tx._id].type]
  const isCustomer = customers.find(v => v.Id === meta[tx._id].name)
  return {
    accountId: meta[tx._id]?.accountId,
    amount: tx.amount,
    description: tx.description || tx.memo,
    type: type.label,
    txnDate: formatDate(tx.date),
    fundingAccountId: account.platformId + '',
    credit: !!account?.isLiability,
    vendor: !isCustomer ? meta[tx._id].name : undefined,
    customer: isCustomer ? meta[tx._id].name : undefined
  }
}

function getTypeOptions(isCredit: boolean, isBank: boolean) {
  if (!isBank)
    return [
      { label: TRANSACTION_TYPES.JE, value: 0 }
    ]
  return !isCredit
    ? [
        { label: TRANSACTION_TYPES.EXPENSE, value: 0 },
        { label: TRANSACTION_TYPES.DEPOSIT, value: 1 }
      ]
    : [
        { label: TRANSACTION_TYPES.EXPENSE, value: 0 },
        { label: TRANSACTION_TYPES.CC, value: 1 }
      ]
}