import { useState } from 'react'
import { Button, Divider, Flex, List, Typography, notification } from 'antd'
import { RutterLinkOptions, useRutterLink } from 'react-rutter-link'

import { CardIntegration } from 'components/CardIntegration'
import { MatchAccounts } from 'components/MatchAccounts'

import { useNewCompany } from '.'
import { useAccountsById } from 'hooks/useAccounts'

import { api } from 'utils/axios'
import { getErrorMsg } from 'utils/geterror'
import { ConnectionType } from 'types/company.types'
import { ConnectionAction, IConnectionItem } from 'types/connection.types'

const { Text } = Typography

interface IProps {
  onNext: () => void
  onBack?: () => void
}

export function IntegrationStep(props: IProps) {
  const company = useNewCompany(s => s.company)
  const setCompany = useNewCompany(s => s.setCompany)

  const accounts = useAccountsById(company?.id)

  const [loading, setLoading] = useState(false)

  const rutter = (company?.connections || [])
    .filter((i) => i.type.id === ConnectionType.Rutter)
    .map(i => ({ ...i, connectionInfo: JSON.parse(i.connectionInfo.toString()) }))

  const rutterConfig: RutterLinkOptions = {
    publicKey: 'f1d459e4-c68f-459f-8655-fc5feeb48437', // PROD
    onSuccess: async (token: string) => {
      if (!company?.id) return notification.error({ message: 'Something went wrong' })
      setLoading(true)
      const postData = { token, provider: 'Rutter' }
      await api.post(`companies/${company?.id}/connection/`, postData)
      notification.success({ message: 'Connected to Shopify' })
      const n = await api.get(`companies/${company?.id}`)
      setCompany(n.data)
      setLoading(false)
    }
  }

  const { open } = useRutterLink(rutterConfig)
  
  const onClick = (item: IConnectionItem, action: ConnectionAction) => {
    if (!company?.id) return notification.error({ message: 'Something went wrong' })
    if (action === ConnectionAction.Connect) {
      if (item.title === 'Shopify') open({ platform: 'Shopify' })
    }
  }

  const isShopify = !!rutter.length
  const isSync = rutter.some((i) => i.status === 2)
  const isConnected = !isSync && rutter.some((i) => i.status === 1)

  items[0].connected = isConnected
  items[0].status = isShopify ? 
    isSync ? 'syncing' : 'connected'
    : 'not connected'

  const matched = company?.settings?.merchant?.matched || {}

  const onMatchedChange = async (value: Matched) => {
    setLoading(true)
    const settings = { ...company?.settings, merchant: { matched: value } }
    api.put(`companies/${company?.id}`, { settings })
      .then((res) => setCompany(res.data))
      .then(() => notification.success({ message: 'Settings updated' }))
      .catch((error) => notification.error(getErrorMsg(error)))
      .finally(() => setLoading(false))
  }

  return (
    <Flex vertical>
      <Divider orientation="left">Integration</Divider>
      <List
        grid={{ gutter: 16, column: 4 }}
        dataSource={items}
        renderItem={(item) => (
          <List.Item key={item.title}>
            <CardIntegration item={item} onClick={onClick} />
          </List.Item>
        )}
      />
      {isShopify && (
        <Flex vertical gap={20}>
          <Divider orientation="left">Settings</Divider> 
          <Text>We need to know how you match your e-commerce data to your general ledger accounts</Text>
          <MatchAccounts
            value={matched}
            onChange={onMatchedChange}
            accounts={accounts.data || []}
            loading={accounts.isLoading}
            options={shopifyAccounts}
            placeholder='Select Account(s)'
            multiple
          />
        </Flex>
      )}
      <Divider />
      <Flex gap={10}>
        <Button onClick={props.onBack}>Back</Button>
        {isShopify ? (
          <Button onClick={props.onNext} loading={loading}>Next</Button>
        ) : (
          <Button type="primary" onClick={props.onNext} loading={loading}>Skip</Button> 
        )}
      </Flex>
    </Flex>
  )
}

const items: IConnectionItem[] = [
  {
    image: require('assets/shopify.png'),
    title: 'Shopify',
    disabled: false,
  },
  {
    image: require('assets/amazon.png'),
    title: 'Amazon',
    disabled: true,
  },
  {
    image: require('assets/walmart.png'),
    title: 'Walmart',
    disabled: true,
  },
  {
    image: require('assets/etsy.png'),
    title: 'Etsy',
    disabled: true,
  },
  {
    image: require('assets/squarespace.png'),
    title: 'Squarespace',
    disabled: true,
  },
  {
    image: require('assets/ebay.png'),
    title: 'Ebay',
    disabled: true,
  }
]

const shopifyAccounts = [
  {id: 'gross', name: 'Gross Sales', isCalc: false},
  {id: 'discount', name: 'Discounts', isCalc: false},
  {id: 'returns', name: 'Returns', isCalc: false},
  {id: 'net', name: 'Net Sales', isCalc: true},
  {id: 'shipping', name: 'Shipping', isCalc: false},
  // {id: 'total', name: 'Total', isCalc: true},
  // {id: 'payments', name: 'Payments', isCalc: true},
]

type Matched = { [key: string]: string[] }
