import { Navigate, Route, Routes } from 'react-router-dom'
import { BrowserRouter as Router } from 'react-router-dom'

import { Protected } from './Protected'

// Auth pages
import { SignInPage } from 'pages/auth/SignIn.page'
import { SignUpPage } from 'pages/auth/SignUp.page'
import { VerifyEmailPage } from 'pages/auth/VerifyEmail.page'
import { ForgotPasswordPage } from 'pages/auth/Forgot.page'
import { ResetPasswordPage } from 'pages/auth/Reset.page'

// App pages
import { AccountPage } from 'pages/account'
import { PersonalPage } from 'pages/account/Personal'
import { BillingPage } from 'pages/account/Billing'
import { HelpPage } from 'pages/help'
import { AllCompaniesPage } from 'pages/home/AllCompanies.page'

import { TeamPage } from 'pages/team'
import { AdminPage } from 'pages/admin'

import { WorkflowPage } from 'pages/workfow'
import { ProjectTasksPage } from 'pages/workfow/ProjectTasks'

// Company pages
import { CompanyPage } from 'pages/company'
import { SettingsPage } from 'pages/company/settings'
import { AnalyticsPage } from 'pages/company/analytics'
import { ReconcilePage } from 'pages/company/reconcile'
import { RecResult } from 'pages/company/reconcile/Result'
import { RecRun } from 'pages/company/reconcile/Run'
import { RecHistory } from 'pages/company/reconcile/History'
import { ReviewPage } from 'pages/company/review'
import { ReviewRun } from 'pages/company/review/ReviewRun'
import { ReviewRules } from 'pages/company/review/ReviewRules'
import { ReviewResult } from 'pages/company/review/ReviewResult'
import { ReviewRule } from 'pages/company/review/ReviewRule'
import { ReviewHistory } from 'pages/company/review/ReviewHistory'

// Other pages
import { QboConnectionPage } from 'pages/connection/QboConnection'
import { AppSumo } from 'pages/auth/AppSumo.page'

export function AppRoutes() {
  return (
    <Router>
      <Routes>
        <Route path="signin" element={<SignInPage />} />
        <Route path="signup" element={<SignUpPage />} />
        <Route path="appsumo" element={<AppSumo />} />
        <Route path="verify-email" element={<VerifyEmailPage />} />
        <Route path="forgot" element={<ForgotPasswordPage />} />
        <Route path="reset" element={<ResetPasswordPage />} />
        <Route path="connection/qbo" element={<QboConnectionPage />} />
        <Route path="/" element={<Protected />}>
          <Route index element={<AllCompaniesPage />} />
          <Route path="admin" element={<AdminPage />} />
          <Route path="account" element={<AccountPage />}>
            <Route index element={<PersonalPage />} />
            <Route path="billing" element={<BillingPage />} />
          </Route>
          <Route path="team" element={<TeamPage />} />
          <Route path="workflow/:tab" element={<WorkflowPage />} />
          <Route path="workflow/projects/:id" element={<ProjectTasksPage />} />
          <Route path="help" element={<HelpPage />} />
          <Route path="company/:id" element={<CompanyPage />}>
            <Route index element={<AnalyticsPage />} />
            <Route path="review" element={<ReviewPage />}>
              <Route index element={<ReviewRun />} />
              <Route path="run" element={<ReviewRun />} />
              <Route path="rules" element={<ReviewRules />} />
              <Route path="rules/:id" element={<ReviewRule />} />
              <Route path="history" element={<ReviewHistory />} />
              <Route path="result/:id" element={<ReviewResult />} />
              <Route path="result" element={<ReviewResult />} />
            </Route>
            <Route path="reconcile" element={<ReconcilePage />}>
              <Route index element={<RecRun />} />
              <Route path="history" element={<RecHistory />} />
              <Route path="result/:id" element={<RecResult />} />
              <Route path="result" element={<RecResult />} />
            </Route>
            <Route path="settings/*" element={<SettingsPage />} />
          </Route>
        </Route>
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    </Router>
  )
}